@keyframes fill-animation {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}

@keyframes arrow-loop {
    10% {
        left: 0;
        opacity: 1;
    }

    30% {
        left: 2rem;
        opacity: 1;
    }

    31% {
        opacity: 0;
        left: -2rem;
    }

    32% {
        left: -2rem;
        opacity: 1;
    }

    100% {
        opacity: 1;
        left: 0;
    }
}
