@use 'mixins' as *;
@use 'variables' as *;

%container {
    margin-left: auto;
    margin-right: auto;
    padding: 0 2rem;
    width: 100%;
    max-width: 129rem;

    @include media(xl) {
        padding: 0 3rem;
    }
}

%card-link {
    position: absolute;
    inset: 0;
    z-index: 1;
    cursor: pointer;
}

%caption {
    font-family: $font-family-regular;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: $font-weight-regular;
    line-height: 100%;
    letter-spacing: 0.02rem;

    @include media(xxl) {
        font-size: 1.4rem;
    }
}

%ol {
    padding-inline-start: 1.5rem;
    font-family: $font-family-regular;
    font-size: 1.6rem;
    font-weight: $font-weight-regular;
    line-height: 130%;

    @include media(xxl) {
        padding-inline-start: 1.7rem;
        font-size: 1.8rem;
    }

    li {
        list-style: decimal;

        &:not(:last-child) {
            margin-bottom: 0.8rem;

            @include media(m) {
                margin-bottom: 1.2rem;
            }
        }
    }
}

%ul {
    padding-inline-start: 1.5rem;
    font-family: $font-family-regular;
    font-size: 1.6rem;
    font-weight: $font-weight-regular;
    line-height: 130%;

    @include media(xxl) {
        padding-inline-start: 1.7rem;
        font-size: 1.8rem;
    }

    li {
        list-style: square;

        &:not(:last-child) {
            margin-bottom: 0.8rem;

            @include media(m) {
                margin-bottom: 1.2rem;
            }
        }
    }
}

%p {
    font-family: $font-family-regular;
    font-size: 1.6rem;
    font-weight: $font-weight-regular;
    line-height: 130%;

    @include media(xxl) {
        font-size: 1.8rem;
    }
}

%link {
    color: inherit;
    text-decoration: underline;
    text-decoration-thickness: 0.1rem;
    text-underline-offset: 0.4rem;
    transition: text-decoration-color $transition-ease;
    cursor: pointer;

    &:hover {
        text-decoration-color: transparent;
    }
}

%link-icons {
    &[href$='.doc'],
    &[href$='.docx'],
    &[href$='.pdf'],
    &[href^='https'] {
        &:after {
            content: '';
            margin-left: 0.6rem;
            width: 1.4rem;
            height: 1.4rem;
            background-position: center center;
            background-repeat: no-repeat;
            display: inline-block;
        }
    }

    &[href$='.pdf'],
    &[href$='.doc'],
    &[href$='.docx'] {
        &:after {
            background-image: url('/img/icon-doc.svg');
        }
    }

    &[href^='https'] {
        &:after {
            background-image: url('/img/icon-external.svg');
        }
    }

    &[href^='https://levler.se/app/fund-boutique/tabs/highlighted'],
    &[href^='https://levler.se']
    {
        &:after {
            display: none;
        }
    }
}

%richtext {
    * {
        &:not(:last-child, h1, h3, h4, h5, h6, li) {
            margin-bottom: 2.4rem;
        }
    }

    h2,
    h3,
    h4,
    h5,
    h6,
    ol,
    ul,
    p {
        i {
            font-style: italic;
        }

        b,
        strong {
            font-family: $font-family-bold;
            font-weight: $font-weight-bold;
        }
    }

    h1 {
        font-family: $font-family-bold;
        font-size: 3rem;
        font-weight: $font-weight-bold;
        line-height: 100%;

        @include media(xxl) {
            font-size: 3.6rem;
        }
    }

    h2 {
        font-family: $font-family-bold;
        font-size: 2.4rem;
        font-weight: $font-weight-bold;
        line-height: 100%;
        margin-bottom: 2.4rem;

        @include media(xxl) {
            font-size: 2.8rem;
        }
    }

    h3,
    h4,
    h5,
    h6 {
        font-family: $font-family-bold;
        font-size: 1.6rem;
        font-weight: $font-weight-bold;
        line-height: 100%;
        margin-bottom: 1.6rem;

        @include media(xxl) {
            font-size: 1.8rem;
        }
    }

    p {
        @extend %p;
    }

    p + *:not(p) {
        margin-top: 3.2rem;
    }

    a {
        @extend %link;
        @extend %link-icons;
    }

    small {
        font-family: $font-family-regular;
        font-size: 1.2rem;
        font-weight: $font-weight-regular;
        font-style: italic;
        line-height: 100%;
    }

    ol {
        @extend %ol;
    }

    ul {
        @extend %ul;
    }
}
